import Image from "next/image";
import Link from "next/link";

import logo from "../../public/images/logo/footer-logo.png";

import CopyRight from "./CopyRight";

import SingleFooter from "./FooterProps/SingleFooter";

const FooterOne = ({ bgColor }) => {

  const footerDetails = {
    "description": "A new innovative, dynamic, social and engaing way of learning. Understand and Develop your talent with our premium upskilling platform.",
    "subDescription": "Register now and Get Started",
    "descriptionTwo": "2000+ of our Learners subscribe from across the world. Don’t be left out, subscribe today and get the latest updates from us!",
    "phone": "(406) 555-0120",
    "mail": "admin@example.com",
    "address": "North America, USA",
    "ourCompany": [
      {
        "text": "Who we are",
        "link": "/pages/company-overview"
      },
      {
        "text": "⁠⁠How we train",
        "link": "/pages/our-training-approach"
      },
      {
        "text": "What you get",
        "link": "/pages/certificates"
      },
      {
        "text": "View all Courses",
        "link": "/courses"
      },
      {
        "text": "Tailor Your Course",
        "link": "/tailor-your-course"
      },
      {
        "text": "Coporate Coaching",
        "link": "/pages/corporate"
      },
      {
        "text": "About our App",
        "link": "/"
      }

    ],

    "usefulLinks": [
      {
        "text": "Upcoming Events",
        "link": "/pages/event-list"
      },
      {
        "text": "Get Membership",
        "link": "/subscription"
      },
      {
        "text": "Work With Us",
        "link": "/work-with-us"
      },
      {
        "text": "Development Partners",
        "link": "/development-partners"
      },
      {
        "text": "Our Research",
        "link": "/blog-grid-minimal"
      },
      {
        "text": "Our Blog",
        "link": "/blog-grid"
      },
      {
        "text": "Get Help",
        "link": "/pages/faqs"
      }
    ],
    "socialLink": [
      {
        "icon": "feather-facebook",
        "link": "https://www.facebook.com/"
      },
      {
        "icon": "feather-twitter",
        "link": "https://www.twitter.com"
      },
      {
        "icon": "feather-instagram",
        "link": "https://www.instagram.com/"
      },
      {
        "icon": "feather-linkedin",
        "link": "https://www.linkdin.com/"
      }
    ]
  };

  return (
    <>
      <footer
        className={`rbt-footer footer-style-1 ${bgColor ? bgColor : "bg-color-white"
          } overflow-hidden`}
      >
        <div className="footer-top">
          <div className="container">
            <div className="row g-5">
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget">
                  <div className="logo">
                    <Link href="/">
                      <Image
                        src={logo}
                        width={300}
                        height={100}
                        alt="Edu-cause"
                      />
                    </Link>
                  </div>

                  <p className="description mt--20">{footerDetails?.description} <span style={{ fontWeight: 'bold' }}>{footerDetails.subDescription}</span></p>

                  <ul className="social-icon social-default justify-content-start">
                    {footerDetails?.socialLink.map((value, innerIndex) => (
                      <li key={innerIndex}>
                        <Link href={value.link}>
                          <i className={value.icon}></i>
                        </Link>
                      </li>
                    ))}
                  </ul>

                  <div className="contact-btn mt--30">
                    <Link
                      className="rbt-btn hover-icon-reverse btn-border-gradient radius-round"
                      href="/contact"
                    >
                      <div className="icon-reverse-wrapper" style={{zIndex: 0}}>
                        <span className="btn-text">Contact Us</span>
                        <span className="btn-icon">
                          <i className="feather-arrow-right"></i>
                        </span>
                        <span className="btn-icon">
                          <i className="feather-arrow-right"></i>
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <SingleFooter
                classOne="col-lg-3 col-md-6 col-sm-6 col-12"
                title="Our Company"
                footerType={footerDetails?.ourCompany}
                playStore={true}
              />

              <SingleFooter
                classOne="col-lg-3 col-md-6 col-sm-6 col-12"
                title="Useful Links"
                footerType={footerDetails?.usefulLinks}
                playStore={false}
              />

              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget">
                  <h5 className="ft-title">Get In Touch With Us</h5>
                  <ul className="ft-link">
                    <li>
                      <span>Enquiry: </span>
                      <Link href="info@learningking.academy">info@learningking.academy</Link>
                    </li>
                    <li>
                      <span>Support:</span>{" "}
                      <Link href="(support@learningking.academy">support@learningking.academy</Link>
                    </li>
                  </ul>

                  <form className="newsletter-form mt--20" action="#">
                    <h6 className="w-600" style={{color: "white"}}>Newsletter</h6>
                    <p className="description">{footerDetails?.descriptionTwo}</p>

                    <div className="form-group right-icon icon-email mb--20">
                      <input
                        id="email"
                        type="email"
                        placeholder="Enter Your Email Here"
                      />
                    </div>

                    <div className="form-group mb--0" style={{zIndex: 0}}>
                      <button
                        className="rbt-btn rbt-switch-btn btn-gradient radius-round btn-sm"
                        style={{zIndex: 0}}
                        type="submit"
                      >
                        <span data-text="Submit Now">Submit Now</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <CopyRight />
      </footer>
    </>
  );
};

export default FooterOne;
