import Link from "next/link";


const SingleFooter = ({ classOne, title, footerType, playStore }) => {
  return (
    <div className={classOne}>
      <div className="footer-widget">
        <h5 className="ft-title">{title}</h5>
        <ul className="ft-link">
          {footerType.map((value, innerIndex) => (
            <li key={innerIndex}>
              <Link href={value.link}>{value.text}</Link>
            </li>
          ))}
        </ul>
        <div className="contact-btn mt--50">
          <Link style={{zIndex: 0}}
            className="rbt-btn hover-icon-reverse btn-border-gradient radius-round"
            href="/contact"
          >
            <div className="icon-reverse-wrapper" style={{zIndex: 0}}>
              <span className="btn-text">{playStore && playStore === true ? 'Play Store' : 'App Store'}</span>
              <span className="btn-icon">
                <i className="feather-arrow-right"></i>
              </span>

              <span className="btn-icon">
                <i className="feather-arrow-right"></i>
              </span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SingleFooter;
